import { Space, Flex, Card, Input, Button } from "antd";
import { Text } from "../../components/ui/typo/Text";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useState } from "react";
import { useForm, SubmitHandler, Controller } from "react-hook-form"
import authService from "../../services/auth.service";

type Inputs = {
  email: string
}

export default function LoginPage() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [previousUrl, setPreviousUrl] = useState<string>(searchParams.get('previous_url') || '');
  const [isInvalidUser, setIsInvalidUser] = useState(false);

  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors, isDirty, isValid},
  } = useForm<Inputs>()

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    try {
      setIsInvalidUser(false)
      const res = await authService.generateToken(data);
      navigate(`/validation-code?previous_url=${previousUrl}`, { state: { token_login: res.token_login } });
    } catch (error) {
      setIsInvalidUser(true)
    }
  }

  return (
    <div className="login-page">

        <div className="login-form-wrapper">
          <Flex vertical gap={48}>
            <img src="./img/login-logo.svg" alt="Logo" />
            <Space direction="vertical" size={32} style={{ width: "100%" }}>
              <Space
                direction="vertical"
                size={12}
                style={{ width: "100%", alignItems: "center" }}
              >
                <Text target="LoginTitle">Connexion</Text>
                <Text target="Regular-Invert-Secondary">
                  Bienvenue ! Merci d’entrer vos informations
                </Text>
              </Space>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Space direction="vertical" size={24} style={{ width: "100%" }}>
                  <Space direction="vertical" size={6} style={{ width: "100%" }}>
                  <Text target="Bold-Invert-Secondary">Email</Text>
                  <Controller
                    name="email"
                    rules={{ required: true}}
                    control={control}
                    render={({ field }: any) => <Input placeholder="Enter your email"  {...field} />}
                  />

                  </Space>
                  <Button disabled={!isDirty || !isValid} type="primary" style={{ width: "100%" }} htmlType="submit">
                    <Text target="H3">Se connecter</Text>
                  </Button>

                  {isInvalidUser && <Space direction="vertical" style={{alignItems: "center", width: "100%"}}>
                    <Text target="Bold" className="error">Impossible de se connecter avec cet email !</Text>
                  </Space>}
                
                  <Space direction="vertical" style={{alignItems: "center", width: "100%", textAlign: 'center'}}>
                    <Text target="Regular-Invert-Secondary" style={{ textAlign: 'center', fontSize: 12}}>
                        Si vous ne souhaitez plus recevoir d'e-mail de MyDTS ou supprimer votre compte, merci d’envoyer un mail à contact@transports-dts.com
                    </Text>
                  </Space>

                </Space>
              </form>
            </Space>
          </Flex>
        </div>
    </div>
  );
}
