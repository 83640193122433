import { Button, Card, Col, Flex, Form, Row, Space } from "antd";
import { SaveOutlined, SendOutlined } from "@ant-design/icons";
import { Text } from "../../ui/typo/Text";
import * as yup from "yup"
import { useFieldArray, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useMemo, useState } from "react";
import FormSelectSingle from "../../form/form-select-single";
import { PlusOutlined } from "@ant-design/icons";
import FormDatePicker from "../../form/form-datepicker";
import FormTimePicker from "../../form/form-timepicker";
import FormNumberInput from "../../form/form-number-input";
import FormCheckbox from "../../form/form-checkbox";
import FormTextarea from "../../form/form-textarea";
import dateHelper from "../../../utils/date.helper";
import roadDemandesService from "../../../services/road-demandes.service";
import { addToast } from "../../../store/toasts/reducer";
import Toast from "../../../models/utils/toast";
import { useAppDispatch } from "../../../store/hooks";
import RoadDemande from "../../../models/RoadDemande";
import dayjs, { Dayjs } from 'dayjs';
import SubformStopPoint from "../../form/subform-stop-point";
import InputFile from "../../ui/input-file/input-file";
import Devis from "../../../models/Devis";
import axios from "axios";
import FormAddressAutoComplete from "../../form/form-address-auto-complete";
import FormInput from "../../form/form-input";
import { ROAD_QUOTATION_DANGEROUS_CLASSES, ROAD_QUOTATION_OPTIONS } from "./types/constants";
import { StatusRoadDemande } from "../../../models/enums/status-road-demande.enum";
import ReadonlyField from "../../form/readonly-field";
import StatusRoadCotation from "./status-road-cotation";
import { Icon } from "../../ui/typo/Icon";


type Inputs = {
    origin: string,
    destination: string,
    stop_points: any[],
    origin_date_date_start: Dayjs,
    origin_date_hours_start: Dayjs,
    origin_date_date_end: Dayjs,
    origin_date_hours_end: Dayjs,
    origin_date_hours: Date,
    destination_date_date_start: Dayjs,
    destination_date_hours_start: Dayjs,
    destination_date_date_end: Dayjs,
    destination_date_hours_end: Dayjs,
    destination_date_hours: Date,
    features: FeatureObject
}

type FeatureObject = {
    unit: string,
    quantity: number,
    total_weight: number,
    dangerous_substances_class: boolean,
    is_controlled_temperature: boolean,
    controlled_temperature_min?: number;
    controlled_temperature_max?: number;
    has_option_goods_on_returnable_pallets: boolean,
    nb_returnable_pallets?: number,
    goods_value: number
    is_import_customs: boolean,
    is_export_customs: boolean,
    required_equipment: string,
    instructions: string,
}

interface IProps{
    cotation?: RoadDemande
    devis?: Devis
    canEdit: boolean
    onSaved: (cotation: RoadDemande) => void
    onClose: () => void;
}
const attachmentType = 'client';
export default function FormQuotationInformations({ cotation, devis, canEdit, onSaved, onClose }: IProps) {

    const schema: any = yup.object().shape({
        origin: yup.string().required("L'adresse de chargement est requise !"),
        destination: yup.string().required("La destination de chargement est requise !"),
        stop_points: yup.array().of(yup.object().shape({
            address: yup.string().required("L'adresse de l'arrêt est requise !"),
            type: yup.string().required("Le type de l'arrêt est requis !"),
            date_start_date: yup.string().nullable(),
            date_start_hours: yup.string().nullable(),
            date_end_date: yup.string().nullable(),
            date_end_hours: yup.string().nullable(),
        })),

        origin_date_date_start: yup.string().nullable(),
        origin_date_hours_start: yup.string().nullable(),
        origin_date_date_end: yup.string().nullable(),
        origin_date_hours_end: yup.string().nullable(),

        destination_date_date_start: yup.string().nullable(),
        destination_date_hours_start: yup.string().nullable(),
        destination_date_date_end: yup.string().nullable(),
        destination_date_hours_end: yup.string().nullable(),
       
        features: yup.object().shape({
            unit: yup.string().required("Le choix de l'unité est requise!"),
            quantity: yup.number().required("La quantité est requise!").min(1, "La quantité doit être supérieur à 0"),
            total_weight: yup.number().transform((value, originalValue) => {
                return originalValue === '' ? NaN : value;
            }).required("Le poids total est requis!")
             .typeError("Le poids total doit être un nombre"),
            dangerous_substances_class: yup.string(),
            is_controlled_temperature: yup.boolean(),
            controlled_temperature_min: yup.string(),
            controlled_temperature_max: yup.string(),
            goods_value: yup.number().transform((value, originalValue) => {
                return originalValue === '' ? NaN : value;
            }).typeError("La valeur de marchandise doit être un nombre"),
            is_import_customs: yup.boolean(),
            is_export_customs: yup.boolean(),
            has_option_goods_on_returnable_pallets: yup.boolean(),
            required_equipment: yup.string(),
            instructions: yup.string(),
        })
    });

    const {
        handleSubmit,
        control,
        reset,
        formState: { isDirty, isValid, errors, dirtyFields },
        watch,
        setValue
    } = useForm<Inputs>({
        resolver: yupResolver(schema), mode: 'onChange',
        defaultValues: formatDefaultValues(cotation)
    })

    function formatDefaultValues(cotation?: RoadDemande) {
        return  {
            origin: cotation?.origin?.raw,
            destination: cotation?.destination?.raw,
            origin_date_date_start: cotation?.origin_date_start ? dayjs(cotation?.origin_date_start) : undefined,
            origin_date_hours_start: cotation?.origin_date_start ? dayjs(cotation?.origin_date_start) : undefined,
            origin_date_date_end: cotation?.origin_date_end ? dayjs(cotation?.origin_date_end) : undefined,
            origin_date_hours_end: cotation?.origin_date_end ? dayjs(cotation?.origin_date_end) : undefined,
            destination_date_date_start: cotation?.destination_date_start ? dayjs(cotation?.destination_date_start) : undefined,
            destination_date_hours_start: cotation?.destination_date_start ? dayjs(cotation?.destination_date_start) : undefined,
            destination_date_date_end: cotation?.destination_date_end ? dayjs(cotation?.destination_date_end) : undefined,
            destination_date_hours_end: cotation?.destination_date_end ? dayjs(cotation?.destination_date_end) : undefined,
            features: {
                quantity: cotation?.features?.quantity ? cotation?.features?.quantity : 1,
                total_weight: cotation?.features?.total_weight ? cotation?.features?.total_weight : undefined,
                unit: cotation?.features?.unit ? cotation?.features?.unit : '',
                dangerous_substances_class: cotation?.features?.dangerous_substances_class ? cotation?.features?.dangerous_substances_class : undefined,
                is_controlled_temperature: cotation?.features?.controlled_temperature_min != null || cotation?.features?.controlled_temperature_max != null,
                controlled_temperature_min: cotation?.features?.controlled_temperature_min,
                controlled_temperature_max: cotation?.features?.controlled_temperature_max,
                has_option_goods_on_returnable_pallets: cotation?.features?.nb_returnable_pallets != null && cotation?.features?.nb_returnable_pallets != 0,
                nb_returnable_pallets: cotation?.features?.nb_returnable_pallets,
                goods_value: cotation?.features?.goods_value ? cotation?.features?.goods_value : undefined,
                required_equipment: cotation?.features?.required_equipment ? cotation?.features?.required_equipment : '',
                instructions: cotation?.features?.instructions ? cotation?.features?.instructions : '',
            },

            stop_points: cotation?.stop_points ? cotation.stop_points.map(stop_point => {
                const res =  {
                    ...stop_point,
                    date_start_date: stop_point.date_start ? dayjs(stop_point.date_start) : '',
                    date_start_hours: stop_point.date_start ? dayjs(stop_point.date_start) : '',
                    date_end_date: stop_point.date_start ? dayjs(stop_point.date_end) : '',
                    date_end_hours: stop_point.date_end ? dayjs(stop_point.date_end): '',
                    address: stop_point && stop_point.address ? stop_point.address.raw : ''
                }

                return res;
            }) : [],
        }
    }

     const { fields, append, remove } = useFieldArray({
        control: control as any,
         name: "stop_points", // Nom de votre champ dans le formulaire
     });
    
    const isControlledTemperature = watch('features.is_controlled_temperature');
    const hasOptionGoodsOnReturnablePallets = watch('features.has_option_goods_on_returnable_pallets');
    
    const dispatch = useAppDispatch();
    const [isEditing, setIsEditing] = useState(!cotation || (cotation.status !== StatusRoadDemande.PENDING_PRICE && cotation.status !== StatusRoadDemande.NEW));
    const [loading, setLoading] = useState(false);
    const [tmpClientFiles, setTmpClientFiles] = useState<any>([]);
    const [tmpDeletedClientFiles, setTmpDeletedClientFiles] = useState<string[]>([]);
    const [attachments, setAttachments] = useState<string[]>([]);
    const unitOptions = useMemo(() => ROAD_QUOTATION_OPTIONS, []);

    const dangerousClassOptions = useMemo(() => ROAD_QUOTATION_DANGEROUS_CLASSES, [])

    async function onSubmit(data: any) { 
        try {
            setLoading(true)
            const {
                stop_points,
                origin_date_date_start, origin_date_hours_start,
                origin_date_date_end, origin_date_hours_end,
                destination_date_date_start, destination_date_hours_start,
                destination_date_date_end, destination_date_hours_end,
                features,
                ...rest
            } = data;

            const {
                has_option_goods_on_returnable_pallets,
                is_controlled_temperature,
                ...restFeatures
            } = features;


            const body = {
                ...rest,
                stop_points: stop_points.map((stop: any) => {
                    const { date_start_date, date_start_hours, date_end_date, date_end_hours, ...rest } = stop;
                    return {
                        ...rest,
                        date_start: date_start_date && date_start_hours ?  dateHelper.formatDateAndTime(date_start_date, date_start_hours) : null,
                        date_end: date_end_date && date_end_hours ? dateHelper.formatDateAndTime(date_end_date, date_end_hours) : null,
                    }
                }),
                origin_date_start: origin_date_date_start && origin_date_hours_start ? dateHelper.formatDateAndTime(origin_date_date_start, origin_date_hours_start) : null,
                origin_date_end: origin_date_date_end && origin_date_hours_end ?  dateHelper.formatDateAndTime(origin_date_date_end, origin_date_hours_end) : null,
                destination_date_start: destination_date_date_start && destination_date_hours_start ?  dateHelper.formatDateAndTime(destination_date_date_start, destination_date_hours_start) : null,
                destination_date_end: destination_date_date_end && destination_date_hours_end ? dateHelper.formatDateAndTime(destination_date_date_end, destination_date_hours_end) : null,
                features: restFeatures
            }

            let res = null;
            if (!cotation) {
                res = await roadDemandesService.create(body);
                onSaved(res);
                reset(formatDefaultValues(res));
                dispatch(addToast(new Toast("La cotation de transport a été ajouté !", "success")));
            } else {
                res = await roadDemandesService.update(cotation.id, body);
                onSaved(res);
                reset(formatDefaultValues(res));
                dispatch(addToast(new Toast("La cotation de transport a été modifié !", "success")));
            }

            if (tmpClientFiles && tmpClientFiles.length > 0) {
                for (const file of tmpClientFiles) {
                    await roadDemandesService.uploadAttachment(res.id, file, attachmentType);
                }
                setTmpClientFiles([]);
            }

            if (tmpDeletedClientFiles && tmpDeletedClientFiles.length > 0) {
                for (const file of tmpDeletedClientFiles) {
                    const filename = file.split("/").pop() || '';
                    await roadDemandesService.deleteAttachment(res.id, filename, attachmentType);
                }
                setTmpDeletedClientFiles([]);
            }

            await fetchAttachments();
        } catch (error) {
            dispatch(addToast(new Toast("Une erreur est survenue pendant l'ajout de la cotation de transport !", "error")));
            console.error(error);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        fetchAttachments();
    }, [cotation])
    
    async function fetchAttachments() {
        if (cotation) {
            const res = await roadDemandesService.getAttachments(cotation.id, attachmentType);
            setAttachments(res.map((item: any) => item.public_url));
        }
    }

    async function onDeleteAttachment(url: string) {
        setTmpDeletedClientFiles([...tmpDeletedClientFiles, url]);
        setAttachments(attachments.filter(a => a !== url));
    }

    async function onViewAttachment(url: string) {
        try {
            const response: any = await axios.get(url, {
                responseType: 'blob'
            });
            const blob = response.data;
            const link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            const parts = url.split("/");
            const filename = parts[parts.length - 1];
            link.download = `${filename}`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (error) {
            console.error("Erreur lors du téléchargement du fichier :", error);
            dispatch(addToast(new Toast(`Une erreur est survenue pendant le téléchargement du fichier !`, 'error')))
        }     
    }

    async function validate() {
        try {
            if (!cotation) return;
            await roadDemandesService.send(cotation.id);
            dispatch(addToast(new Toast("La cotation de transport a été envoyé !", "success")));
            onClose();
        } catch (error) {
            dispatch(addToast(new Toast("Une erreur est survenue pendant la validation de la cotation de transport !", "error")));
            console.log(error);
        }
    }

    async function onDelete() {
        try {
            if (cotation) {
                await roadDemandesService.destroy(cotation.id);
                dispatch(addToast(new Toast("La cotation de transport a été supprimé !", "success")));
            }
            onClose();
        } catch (error) {
            dispatch(addToast(new Toast("Une erreur est survenue pendant la suppresion de la cotation de transport !", "error")));
        }
      
    }

    return (
        <>
            {isEditing && <Form style={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }} layout="vertical" requiredMark={false} onSubmitCapture={handleSubmit(onSubmit)}>
                
                <Flex justify="space-between" style={{ flexDirection: "column", height: '100%' }}>
                    <div style={{ height: '100%', overflow: 'scroll' }}>
                        <Space direction="vertical" size={16} style={{ width: "100%" }}>
                            <Text target="SectionTitle">Détails du trajet</Text>

                            <FormAddressAutoComplete
                                label="Adresse de chargement"
                                required
                                name="origin"
                                placeholder="Entrer l'adresse"
                                control={control}
                                error={errors?.origin}
                                disabled={!canEdit}
                            />

                            <Row gutter={[16, 16]}>
                                <Col span={6}>
                                    <FormDatePicker
                                        label="Date de début"
                                        name="origin_date_date_start"
                                        control={control}
                                        error={errors?.origin_date_date_start}
                                        placeholder="-- / -- / ----"
                                        disabled={!canEdit}
                                    />
                                </Col>
                                <Col span={6}>
                                    <FormTimePicker
                                        label="Heure de début"
                                        name="origin_date_hours_start"
                                        control={control}
                                        error={errors?.origin_date_hours}
                                        placeholder="-- : --"
                                        disabled={!canEdit}
                                    />
                                </Col>

                                <Col span={6}>
                                    <FormDatePicker
                                        label="Date de fin"
                                        name="origin_date_date_end"
                                        control={control}
                                        error={errors?.origin_date_date_end}
                                        placeholder="-- / -- / ----"
                                        disabled={!canEdit}
                                    />
                                </Col>
                                <Col span={6}>
                                    <FormTimePicker
                                        label="Heure de fin"
                                        name="origin_date_hours_end"
                                        control={control}
                                        error={errors?.origin_date_hours}
                                        placeholder="-- : --"
                                        disabled={!canEdit}
                                    />
                                </Col>
                            </Row>

                            <FormAddressAutoComplete
                                label="Adresse de livraison"
                                required
                                name="destination"
                                placeholder="Entrer l'adresse"
                                control={control}
                                error={errors?.destination}
                                disabled={!canEdit}
                            />

                            <Row gutter={[16, 16]}>
                                <Col span={6}>
                                    <FormDatePicker
                                        label="Date de début"
                                        name="destination_date_date_start"
                                        control={control}
                                        error={errors?.destination_date_date_start}
                                        placeholder="-- / -- / ----"
                                        disabled={!canEdit}
                                    />
                                </Col>
                                <Col span={6}>
                                    <FormTimePicker
                                        label="Heure de début"
                                        name="destination_date_hours_start"
                                        control={control}
                                        error={errors?.destination_date_hours}
                                        placeholder="-- : --"
                                        disabled={!canEdit}
                                    />
                                </Col>

                                <Col span={6}>
                                    <FormDatePicker
                                        label="Date de fin"
                                        name="destination_date_date_end"
                                        control={control}
                                        error={errors?.destination_date_date_end}
                                        placeholder="-- / -- / ----"
                                        disabled={!canEdit}
                                    />
                                </Col>
                                <Col span={6}>
                                    <FormTimePicker
                                        label="Heure de fin"
                                        name="destination_date_hours_end"
                                        control={control}
                                        error={errors?.destination_date_hours}
                                        placeholder="-- : --"
                                        disabled={!canEdit}
                                    />
                                </Col>
                            </Row>

                            <SubformStopPoint
                                fieldName="stop_points"
                                control={control}
                                fields={fields}
                                onRemove={remove}
                                errors={errors}
                                canEdit={canEdit}
                                setValue={setValue}
                            />

                            <Button
                                type="default"
                                icon={<PlusOutlined size={20} />}
                                onClick={() => append({
                                    address: "",
                                    type: "",
                                    date_start_date: "",
                                    date_start_hours: undefined,
                                    date_end_date: "",
                                    date_end_hours: undefined
                                })}
                                style={{ width: "100%" }}
                                disabled={!canEdit}
                            >
                                <Text target="Bold">Ajouter un arrêt suplémentaire</Text>
                            </Button>

                        </Space>

                        <Space direction="vertical" size={16} style={{ width: "100%", marginTop: 20 }}>
                            <Text target="SectionTitle">Détails sur la marchandise</Text>
                        
                            <Row gutter={16}>
                                <Col span={8}>
                                    <FormSelectSingle
                                        label="Choix de l'unité"
                                        placeholder="Choisir unité"
                                        name="features.unit"
                                        control={control}
                                        options={unitOptions}
                                        onSearch={() => console.log('todo')}
                                        required
                                        disabled={!canEdit}
                                    />
                                </Col>
                                
                                <Col span={8}>
                                    <FormNumberInput
                                        label="Nombre"
                                        name="features.quantity"
                                        control={control}
                                        required
                                        error={errors?.features?.quantity}
                                        disabled={!canEdit}
                                    />
                                </Col>
                                <Col span={8}>
                                    <FormInput
                                        label="Poids en kg"
                                        required
                                        name="features.total_weight"
                                        placeholder="Poids en kg"
                                        control={control}
                                        error={errors?.features?.total_weight}
                                        disabled={!canEdit}
                                    />
                                    
                                </Col>
                            </Row>
                            

                            
                            <FormSelectSingle
                                label="Matière dangereuse"
                                placeholder="Choisir la matière"
                                name="features.dangerous_substances_class"
                                control={control}
                                options={dangerousClassOptions}
                                onSearch={() => null}
                                disabled={!canEdit}
                            />

                            <Row gutter={16}>
                                <Col span={8}>
                                    <FormCheckbox
                                        label="Température dirigée"
                                        name="features.is_controlled_temperature"
                                        control={control}
                                        disabled={!canEdit}
                                        defaultValue={cotation?.features?.controlled_temperature_min != null || cotation?.features?.controlled_temperature_max != null}
                                    />
                                </Col>
                                {isControlledTemperature && <>
                                    <Col span={8}>
                                        <FormInput
                                            label="Minimum"
                                            prefix="°C"
                                            placeholder="Minimum"
                                            name="features.controlled_temperature_min"
                                            control={control}
                                            required
                                            error={errors?.features?.controlled_temperature_min}
                                            disabled={!canEdit}
                                        />
                                        
                                    </Col>
                                    <Col span={8}>
                                        <FormInput
                                            label="Maximum"
                                            prefix="°C"
                                            placeholder="Maximum"
                                            name="features.controlled_temperature_max"
                                            control={control}
                                            required
                                            error={errors?.features?.controlled_temperature_max}
                                            disabled={!canEdit}
                                        />
                                    </Col>
                                </>}
                            </Row>
                            
                            <Row gutter={16}>
                                <Col span={8}>
                                    <FormCheckbox
                                        label="Palettes consignées"
                                        name="features.has_option_goods_on_returnable_pallets"
                                        control={control}
                                        disabled={!canEdit}
                                        defaultValue={cotation?.features?.nb_returnable_pallets != null && cotation?.features?.nb_returnable_pallets != 0}
                                    />
                                </Col>

                                {hasOptionGoodsOnReturnablePallets && <Col span={4}>
                                    <FormInput
                                        label="Nombre"
                                        placeholder="Nombre"
                                        name="features.nb_returnable_pallets"
                                        control={control}
                                        required
                                        error={errors?.features?.nb_returnable_pallets}
                                        disabled={!canEdit}
                                    />
                                </Col>}
                            </Row>

                            <Col span={8}>
                                <FormInput
                                    label="Valeur de la marchandise"
                                    prefix="€ HT"
                                    placeholder="Valeur de la marchandise"
                                    name="features.goods_value"
                                    control={control}
                                    error={errors?.features?.goods_value}
                                    disabled={!canEdit}
                                />
                            </Col>
                        </Space>

                        <Space direction="vertical" size={16} style={{ width: "100%" }}>
                            <Text target="SectionTitle">Informations complémentaires</Text>

                            <FormTextarea
                                label="Matériel exigé"
                                name="features.required_equipment"
                                placeholder="Lister le matériel exigé"
                                control={control}
                                error={errors?.features?.required_equipment}
                                disabled={!canEdit}
                            />

                            <FormTextarea
                                label="Instructions"
                                name="features.instructions"
                                placeholder="Entrer les instructions"
                                control={control}
                                error={errors?.features?.instructions}
                                disabled={!canEdit}
                            />

                            <Form.Item label="Pièce(s) jointe(s)" >
                                <InputFile
                                    onUpload={(file: any) => setTmpClientFiles(file)}
                                    existingUrls={attachments}
                                    accept={['.pdf']}
                                    onDelete={(url) => onDeleteAttachment(url)}
                                    onView={(url) => onViewAttachment(url)}
                                    disabled={!canEdit}
                                />
                            </Form.Item>
                        </Space>
                    </div>

                    <div className="ant-drawer-footer">
                        <Space
                            direction="vertical"
                            size={16}
                            style={{ width: "100%" }}
                            align="end"
                        >
                            <Space
                                size={16}
                                style={{ justifyContent: "flex-end", width: "100%" }}
                            >
                                 <Button type="default" onClick={() => onDelete()} >
                                    <Space size={6}>
                                        <Icon path="/icons/trash.svg" size={20} />
                                        <Text target="Bold">Annuler</Text>
                                    </Space>
                                </Button>
                                <Button type="primary" htmlType="submit" disabled={!isDirty || !isValid || loading || !canEdit}>
                                    <Space size={6}>
                                        <SaveOutlined />
                                        <Text target="Bold">Sauvegarder</Text>
                                    </Space>
                                </Button>
                        
                                <Button type="primary" onClick={() => validate()} disabled={(!cotation?.canValidate() || isDirty)}>
                                    <Space size={6}>
                                        <SendOutlined />
                                        <Text target="Bold">Envoyer ma demande</Text>
                                    </Space>
                                </Button>
        
                            </Space>
                        </Space>
                    </div>
                </Flex>
            </Form>}
            {!isEditing && cotation && 
                <Flex justify="space-between" style={{ flexDirection: "column", height: '100%' }}>
                    <div style={{ height: '100%', overflow: 'scroll' }}>
                        <Space direction="vertical" size={16} style={{ width: "100%" }}>

                            <Row gutter={[16, 16]}>
                                <Col span={12}>
                                    <ReadonlyField
                                        label="Date de la demande"
                                        value={cotation.created_at ? dayjs(cotation.created_at).format('DD/MM/YYYY') : '-'}
                                        readonly
                                    />
                                </Col>

                                <Col span={12}>
                                    <ReadonlyField label="Statut" readonly contentReadonly={<StatusRoadCotation status={cotation.status} />} />
                                </Col>
                            </Row>

                            <Text target="SectionTitle">Détails du trajet</Text>

                            <Row gutter={[16, 16]}>
                                <Col span={12}>
                                    <ReadonlyField
                                        label="Adresse de chargement"
                                        value={cotation.origin?.raw}
                                        readonly
                                    />
                                </Col>

                                <Col span={12}>
                                    <ReadonlyField
                                        label="Date"
                                        value={cotation.origin_date_start ? dayjs(cotation.origin_date_start).format('DD/MM/YYYY') : '-'}
                                        readonly
                                    />
                                </Col>

                                <Col span={12}>
                                    <ReadonlyField
                                        label="Adresse de livraison"
                                        value={cotation.destination?.raw}
                                        readonly
                                    />
                                </Col>

                                <Col span={12}>
                                    <ReadonlyField
                                        label="Date"
                                        value={cotation.destination_date_start ? dayjs(cotation.destination_date_start).format('DD/MM/YYYY') : '-'}
                                        readonly
                                    />
                                </Col>
                            </Row>

                            <Text target="SectionTitle">Détail sur la marchandise</Text>

                            <Row gutter={[16, 16]}>
                                <Col span={8}>
                                    <ReadonlyField
                                        label="Unité"
                                        value={cotation.features?.getUnitLabel()}
                                        readonly
                                    />
                                </Col>

                                <Col span={8}>
                                    <ReadonlyField
                                        label="Quantité"
                                        value={cotation.features?.quantity}
                                        readonly
                                    />
                                </Col>

                                <Col span={8}>
                                    <ReadonlyField
                                        label="Poids total"
                                        value={`${cotation.features?.total_weight} kg`}
                                        readonly
                                    />
                                </Col>

                                <Col span={8}>
                                    <ReadonlyField
                                        label="Matière dangereuse"
                                        value={cotation.features?.dangerous_substances_class ? 'Oui' : 'Non'}
                                        readonly
                                    />
                                </Col>
                            </Row>

                            <Row gutter={[16, 16]}>
                                <Col span={8}>
                                    <ReadonlyField
                                        label="Température dirigée"
                                        value={cotation.features?.is_controlled_temperature ? 'Oui' : 'Non'}
                                        readonly
                                    />
                                </Col>

                                {cotation.features?.is_controlled_temperature && <>
                                    <Col span={8}>
                                        <ReadonlyField
                                            label="Température minimale"
                                            value={`${cotation.features?.controlled_temperature_min} °C`}
                                            readonly
                                        />
                                    </Col>

                                    <Col span={8}>
                                        <ReadonlyField
                                            label="Température maximale"
                                            value={`${cotation.features?.controlled_temperature_max} °C`}
                                            readonly
                                        />
                                    </Col>
                                </>}
                            </Row>

                            <Row gutter={[16, 16]}>
                                <Col span={8}>
                                    <ReadonlyField
                                        label="Palettes consignées"
                                        value={cotation.features?.has_option_goods_on_returnable_pallets ? 'Oui' : 'Non'}
                                        readonly
                                    />
                                </Col>

                                {cotation.features?.has_option_goods_on_returnable_pallets && <Col span={8}>
                                    <ReadonlyField
                                        label="Nombre"
                                        value={cotation.features?.nb_returnable_pallets}
                                        readonly
                                    />
                                </Col>}
                            </Row>

                            <Row gutter={[16, 16]}>
                                <Col span={8}>
                                    <ReadonlyField
                                        label="Valeur de la marchandise"
                                        value={`${cotation.features?.goods_value || '0'} €`}
                                        readonly
                                    />
                                </Col>
                            </Row>

                            <Text target="SectionTitle">Informations complémentaires</Text>

                            <ReadonlyField
                                label="Matériel exigé"
                                value={cotation.features?.required_equipment}
                                readonly
                            />

                            <ReadonlyField
                                label="Instructions"
                                value={cotation.features?.instructions}
                                readonly
                            />

                            {attachments.length > 0 && <Form.Item label="Pièce(s) jointe(s)" >
                                <InputFile
                                    onUpload={(file: any) => null}
                                    existingUrls={attachments}
                                    accept={['.pdf']}
                                    onDelete={(url) => null}
                                    onView={(url) => null}
                                    disabled={true}
                                    hideDragger={true}
                                    canDelete={false}
                                />
                            </Form.Item>}

                        </Space>
                    </div>

                     <div className="ant-drawer-footer">
                        <Space
                            direction="vertical"
                            size={16}
                            style={{ width: "100%" }}
                            align="end"
                        >
                            <Space
                                size={16}
                                style={{ justifyContent: "flex-end", width: "100%" }}
                            >
                                <Button type="default" onClick={() => onDelete()} >
                                    <Space size={6}>
                                        <Icon path="/icons/trash.svg" size={20} />
                                        <Text target="Bold">Annuler ma demande</Text>
                                    </Space>
                                </Button>

                                <Button type="primary" disabled={cotation.status !== StatusRoadDemande.NEW  } onClick={() => setIsEditing(true)}>
                                    <Space size={6}>
                                        <SaveOutlined />
                                        <Text target="Bold">Editer la demande</Text>
                                    </Space>
                                </Button>
                        
                            </Space>
                        </Space>
                    </div>
                </Flex>
            }
        </>
    )
}